import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";

const Login = React.lazy(() => import('./components/Login/loginpage.jsx'))
const Layout = React.lazy(() => import('./components/Layout/layout.jsx'))

const Home = React.lazy(() => import('./components/Home/home.jsx'));
const UserReports = React.lazy(() => import('./components/UserReports/userReports.jsx'));
const Financials = React.lazy(() => import('./components/Financials/financials.jsx'));
const Contracts = React.lazy(() => import('./components/Contracts/contracts.jsx'));
const AISolutions = React.lazy(() => import('./components/AISolutions/aiSolutions.jsx'));
const Employees = React.lazy(() => import('./components/Employees/employees.jsx'));
const DataMgt = React.lazy(()=> import('./components/DataManagement/dataManagement.jsx') );
const PLFileUpload = React.lazy(()=> import('./components/DataManagement/PLFileUpload/plFileUpload.jsx'))
const CustomersDashboard = React.lazy(()=> import('./components/Customers/customers.jsx'))
const AIAnomalyDetection = React.lazy(()=> import('./components/Property/AIAnomali/aiAnomali.jsx'))


const Administration = React.lazy(() => import("./components/Administration/administration.jsx"))

const ForgotPassword = React.lazy(()=> import ('./components/Login/forgotpassword.jsx'))
const UserManagement = React.lazy(() => import('./components/UserManagement/index.jsx'))
const Insights = React.lazy(() => import('./components/Insights/insights.jsx'))


const PrivateRoutes = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/login' />
  )
}

const PrivateRoutesPWA = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/pwa-login' />
  )
}

export default function Router() {

  return (
    <Suspense>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ForgotPassword />} />



       

          <Route element={<PrivateRoutes />}>
            <Route path="/home/*" element={<Layout />} />
            <Route path="/app" element={<Layout />} >
              <Route index element={<Home />} />
              <Route path="/app/insights" element={<Insights />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="/app/financials" element={<Financials />}/>
              <Route path="/app/user-reports" element={<UserReports />} />
              <Route path="/app/planning" element={<AISolutions />}/>
              <Route path="/app/employees" element={<Employees />}/>
              <Route path="/app/data-mgt" element={<DataMgt />}/>
              <Route path="/app/data-mgt/pl-file-upload" element={<PLFileUpload />}/>
              <Route path="/app/data-mgt/ai-anomaly-detection" element={<AIAnomalyDetection />}/>

              <Route path="/app/contracts" element={<Contracts />} />
              
              <Route path="/app/customers" element={<CustomersDashboard />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>

  );
}
